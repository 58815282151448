import { ASSETS } from '@/assets';
import type { AnchorDetailProps } from '@/pages/anchorDetail/anchorDetailStore';
import { AuthModalStore, FlatAuth } from '@/pages/anchorDetail/authModal';
import { FILE_TYPE, OPUS_TYPE, TAG_CATEGORY_TYPE, VITA_AUDIT_STATUS } from '@/pages/anchorDetail/constants';
import {
  IMAGE_ACCEPT,
  VIDEO_ACCEPT,
  beforeUploadImage,
  beforeUploadVideo,
} from '@/pages/anchorDetail/imgAndVideoUploadHelper';
import type { UserOpusVO } from '@/pages/anchorDetail/types';
import { RenderByPermission, request } from '@/utils';
import { EditOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Button, Checkbox, Image, Tag, Upload, message } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import SliceModal from './sliceModal';
import { SliceStore } from './sliceModal';

const CommonItem = observer(
  ({ anchorDetailStore, item, opusType }: AnchorDetailProps & { item: UserOpusVO; opusType: number }) => {
    const {
      isEdit,
      sliceAndImgStore: { handleDeleteSliceOrImg },
      videoModalStore,
      type,
    } = anchorDetailStore;

    return (
      <div className={'h-[360px] relative flex justify-center items-center'}>
        {!isEdit && (
          <div
            onClick={() => {
              item._checked = !item._checked;
            }}
            className={'absolute left-0 top-0 z-10 size-12 flex justify-center items-center'}
          >
            <Checkbox
              onClick={(event) => {
                event.stopPropagation();
                item._checked = !item._checked;
              }}
              checked={item._checked}
            />
          </div>
        )}
        <Image
          onClick={
            opusType === OPUS_TYPE.works.value
              ? () => videoModalStore.handleOpen({ url: item.fileUrl, coverUrl: item.coverUrl })
              : undefined
          }
          preview={opusType === OPUS_TYPE.works.value ? false : { destroyOnClose: true }}
          rootClassName={`cursor-pointer absolute size-full rounded-tl-1 rounded-tr-1 object-cover ${styles.imgPreview}`}
          src={opusType === OPUS_TYPE.works.value ? item.coverUrl : item.fileUrl}
        />
        {opusType === OPUS_TYPE.works.value && (
          <img
            onClick={() => videoModalStore.handleOpen({ url: item.fileUrl, coverUrl: item.coverUrl })}
            className={'size-[52px] z-10 cursor-pointer'}
            src={ASSETS.COMMON.PLAY}
            alt={''}
          />
        )}

        {isEdit && (
          <div
            onClick={() => handleDeleteSliceOrImg(item.id)}
            className={'flex justify-center items-center size-[52px] absolute top-0 right-0 cursor-pointer'}
          >
            <img
              className={'size-7 z-10'}
              src={ASSETS.ANCHOR_DETAIL_VIDEO_IMAGE_DELETE}
              alt={''}
            />
          </div>
        )}
        {!isEdit && item.auditStatus !== VITA_AUDIT_STATUS.audit.value ? (
          <img
            className={'w-20 h-5 absolute right-0 top-0'}
            src={Object.values(VITA_AUDIT_STATUS).find((val) => val.value === item.auditStatus)?.img}
            alt=""
          />
        ) : null}
        {!isEdit && item.auditStatus === VITA_AUDIT_STATUS.refuse.value && (
          <div
            className={
              'h-15 w-full absolute z-10 bottom-0 bg-heading/70 rounded-tl-1 rounded-tr-1 px-3 flex items-center gap-1'
            }
          >
            <ExclamationOutlined className={'text-error text-16'} />
            <div className={'text-white text-12 line-clamp-2'}>{item.auditReason}</div>
          </div>
        )}
      </div>
    );
  },
);

export const AnchorVideo = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const {
    isEdit,
    isChangeSlice,
    sliceAndImgStore: {
      sliceList,
      isSliceListSelectedAll,
      selectedSliceList,
      handleSliceListChooseAll,
      handleUploadSliceOrImg,
      handleAuthSliceOrImg,
      handleSliceRecommend,
    },
    handleAddTag,
    handleAddSlice,
    handleCategory,
    type,
    sliceStore,
    libId,
  } = anchorDetailStore;

  return (
    <div className={`p-4 rounded-1 border border-solid border-divide ${styles.sliceAndImgContainer}`}>
      <div className={'text-heading text-16 font-500 mb-2'}>主播切片</div>
      {!isEdit && (
        <div className={'flex items-center gap-3 mb-4'}>
          <Checkbox
            onClick={(event) => {
              event.stopPropagation();
              handleSliceListChooseAll();
            }}
            indeterminate={selectedSliceList.length > 0 && selectedSliceList.length !== sliceList.length}
            checked={isSliceListSelectedAll}
          >
            全选
          </Checkbox>
          {type === 'audit' && (
            <RenderByPermission permissionId={'122_54'}>
              <Button
                onClick={() =>
                  handleAuthSliceOrImg(
                    selectedSliceList.map((item) => item.id),
                    '批量审核主播切片',
                  )
                }
                type={'default'}
              >
                批量审核切片
              </Button>
            </RenderByPermission>
          )}
          <RenderByPermission permissionId={['122_56', '67_56']}>
            <Button
              onClick={() =>
                handleAddTag(
                  [],
                  selectedSliceList.map((item) => item.id),
                  TAG_CATEGORY_TYPE.slice.value,
                  true,
                )
              }
              type={'default'}
            >
              批量切片标签
            </Button>
          </RenderByPermission>
          <RenderByPermission permissionId={['122_53', '67_53']}>
            <Button
              onClick={() => handleSliceRecommend(selectedSliceList.map((item) => item.id))}
              type={'default'}
            >
              批量推荐理由
            </Button>
          </RenderByPermission>
        </div>
      )}
      <div className={'flex flex-wrap gap-4'}>
        {sliceList.map((item) => (
          <div
            className={`w-[270px] bg-bg rounded-1 border border-solid transition-all ${item._checked ? 'border-primary' : 'border-transparent'}`}
            key={item.id}
          >
            <CommonItem
              item={item}
              opusType={OPUS_TYPE.works.value}
              anchorDetailStore={anchorDetailStore}
            />
            <div className={'px-4 py-2 flex flex-col gap-2'}>
              <div className={'flex items-center justify-between'}>
                <span className={'text-14 text-heading font-500'}>切片类目</span>
                {!isEdit && (
                  <RenderByPermission permissionId={['122_52', '67_52']}>
                    <Button
                      onClick={() => {
                        handleAddSlice(item.id, item.liveTypeId, item.liveTypeName);
                      }}
                      type={'link'}
                    >
                      <EditOutlined />
                      编辑
                    </Button>
                  </RenderByPermission>
                )}
              </div>
              {isChangeSlice && item.id === libId ? (
                <SliceModal store={sliceStore} />
              ) : (
                <div className={'text-12 text-normal line-clamp-2'}>{item?.liveTypeName}</div>
              )}
            </div>
            <div className={'px-4 py-2 flex flex-col gap-2'}>
              <div className={'flex items-center justify-between'}>
                <span className={'text-14 text-heading font-500'}>切片标签</span>
                {!isEdit && (
                  <RenderByPermission permissionId={['122_52', '67_52']}>
                    <Button
                      onClick={() =>
                        handleAddTag(
                          item?.tagList?.map((val) => val.libId) || [],
                          [item.id],
                          TAG_CATEGORY_TYPE.slice.value,
                        )
                      }
                      type={'link'}
                    >
                      <EditOutlined />
                      编辑
                    </Button>
                  </RenderByPermission>
                )}
              </div>
              <div className={'flex flex-wrap items-start gap-1 max-h-12 overflow-y-auto'}>
                {item.tagList?.map((item, index) => (
                  <Tag
                    className={'m-0'}
                    color={'blue'}
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                  >
                    {item.libName}
                  </Tag>
                ))}
              </div>
              <div className={'flex items-center justify-between'}>
                <span className={'text-14 text-heading font-500'}>推荐理由</span>
                {!isEdit && (
                  <RenderByPermission permissionId={['122_53', '67_53']}>
                    <Button
                      onClick={() => handleSliceRecommend([item.id], item.butlerReview)}
                      type={'link'}
                    >
                      <EditOutlined />
                      编辑
                    </Button>
                  </RenderByPermission>
                )}
              </div>
              <div className={'text-12 text-normal line-clamp-2'}>{item.butlerReview}</div>
            </div>
            {!isEdit && item.auditStatus === VITA_AUDIT_STATUS.audit.value && (
              <RenderByPermission permissionId={'122_50'}>
                <AuthItem
                  anchorDetailStore={anchorDetailStore}
                  id={item.id}
                />
              </RenderByPermission>
            )}
          </div>
        ))}

        {isEdit && (
          <div className={'w-[270px] h-[360px]'}>
            <Upload
              fileList={[]}
              maxCount={1}
              style={{ width: '100%' }}
              accept={VIDEO_ACCEPT}
              showUploadList={false}
              beforeUpload={beforeUploadVideo}
              customRequest={(item) => handleUploadSliceOrImg(item, OPUS_TYPE.works.value, FILE_TYPE.video.value)}
            >
              <div
                className={
                  'h-[360px] w-full flex flex-col justify-center items-center border-2 border-dashed border-divide rounded-2 cursor-pointer'
                }
              >
                <div className={'text-normal text-24'}>+</div>
                <div className={'text-normal text-24'}>上传切片</div>
              </div>
            </Upload>
          </div>
        )}
      </div>
    </div>
  );
});

export const AnchorImg = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const {
    isEdit,
    sliceAndImgStore: {
      imageList,
      isImageListSelectedAll,
      selectedImageList,
      handleImageListChooseAll,
      handleUploadSliceOrImg,
      handleAuthSliceOrImg,
    },
    type,
  } = anchorDetailStore;

  return (
    <div className={`p-4 rounded-1 border border-solid border-divide ${styles.sliceAndImgContainer}`}>
      <div className={'text-heading text-16 font-500 mb-2'}>主播形象照</div>
      {!isEdit && type === 'audit' && (
        <div className={'flex items-center gap-3 mb-4'}>
          <Checkbox
            onClick={(event) => {
              event.stopPropagation();
              handleImageListChooseAll();
            }}
            indeterminate={selectedImageList.length > 0 && selectedImageList.length !== imageList.length}
            checked={isImageListSelectedAll}
          >
            全选
          </Checkbox>
          <RenderByPermission permissionId={'122_55'}>
            <Button
              onClick={() =>
                handleAuthSliceOrImg(
                  selectedImageList.map((item) => item.id),
                  '批量审核主播形象照',
                )
              }
            >
              批量审核形象照
            </Button>
          </RenderByPermission>
        </div>
      )}
      <div className={'flex flex-wrap gap-4'}>
        {imageList.map((item) => (
          <div
            className={`w-[270px] bg-bg rounded-1 border border-solid transition-all ${item._checked ? 'border-primary' : 'border-transparent'}`}
            key={item.id}
          >
            <CommonItem
              item={item}
              opusType={OPUS_TYPE.image.value}
              anchorDetailStore={anchorDetailStore}
            />
            {!isEdit && item.auditStatus === VITA_AUDIT_STATUS.audit.value && (
              <RenderByPermission permissionId={'122_51'}>
                <AuthItem
                  anchorDetailStore={anchorDetailStore}
                  id={item.id}
                />
              </RenderByPermission>
            )}
          </div>
        ))}

        {isEdit && (
          <div className={'w-[270px] h-[360px]'}>
            <Upload
              fileList={[]}
              maxCount={1}
              style={{ width: '100%' }}
              accept={IMAGE_ACCEPT}
              showUploadList={false}
              beforeUpload={beforeUploadImage}
              customRequest={(item) => handleUploadSliceOrImg(item, OPUS_TYPE.image.value, FILE_TYPE.image.value)}
            >
              <div
                className={
                  'h-[360px] w-full flex flex-col justify-center items-center border-2 border-dashed border-divide rounded-2 cursor-pointer'
                }
              >
                <div className={'text-normal text-24'}>+</div>
                <div className={'text-normal text-24'}>上传形象照</div>
              </div>
            </Upload>
          </div>
        )}
      </div>
    </div>
  );
});
export const AnchorMuck = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const {
    isEdit,
    sliceAndImgStore: {
      muckList,
      isMuckListSelectedAll,
      selectedMuckList,
      handleMuckListChooseAll,
      handleUploadSliceOrImg,
      handleAuthSliceOrImg,
    },
    type,
  } = anchorDetailStore;

  return (
    <div className={`p-4 rounded-1 border border-solid border-divide ${styles.sliceAndImgContainer}`}>
      <div className={'text-heading text-16 font-500 mb-2'}>模卡</div>
      {!isEdit && type === 'audit' && (
        <div className={'flex items-center gap-3 mb-4'}>
          <Checkbox
            onClick={(event) => {
              event.stopPropagation();
              handleMuckListChooseAll();
            }}
            indeterminate={selectedMuckList.length > 0 && selectedMuckList.length !== muckList.length}
            checked={isMuckListSelectedAll}
          >
            全选
          </Checkbox>
          <RenderByPermission permissionId={'122_55'}>
            <Button
              onClick={() =>
                handleAuthSliceOrImg(
                  selectedMuckList.map((item) => item.id),
                  '批量审核主播模卡',
                )
              }
            >
              批量审核模卡
            </Button>
          </RenderByPermission>
        </div>
      )}
      <div className={'flex flex-wrap gap-4'}>
        {muckList.map((item) => (
          <div
            className={`w-[270px] bg-bg rounded-1 border border-solid transition-all ${item._checked ? 'border-primary' : 'border-transparent'}`}
            key={item.id}
          >
            <CommonItem
              item={item}
              opusType={OPUS_TYPE.image.value}
              anchorDetailStore={anchorDetailStore}
            />
            {!isEdit && item.auditStatus === VITA_AUDIT_STATUS.audit.value && (
              <RenderByPermission permissionId={'122_51'}>
                <AuthItem
                  anchorDetailStore={anchorDetailStore}
                  id={item.id}
                />
              </RenderByPermission>
            )}
          </div>
        ))}

        {isEdit && (
          <div className={'w-[270px] h-[360px]'}>
            <Upload
              fileList={[]}
              maxCount={1}
              style={{ width: '100%' }}
              accept={IMAGE_ACCEPT}
              showUploadList={false}
              beforeUpload={beforeUploadImage}
              customRequest={(item) => handleUploadSliceOrImg(item, OPUS_TYPE.muck.value, FILE_TYPE.image.value)}
            >
              <div
                className={
                  'h-[360px] w-full flex flex-col justify-center items-center border-2 border-dashed border-divide rounded-2 cursor-pointer'
                }
              >
                <div className={'text-normal text-24'}>+</div>
                <div className={'text-normal text-24'}>上传模卡</div>
              </div>
            </Upload>
          </div>
        )}
      </div>
    </div>
  );
});

const AuthItem = observer(({ id, anchorDetailStore }: { id: string | number } & AnchorDetailProps) => {
  const flatAuthStore = React.useMemo(
    () =>
      new AuthModalStore({
        callback: async (params) => {
          await request({
            url: '/pbb/platform/anchor/opus/audit/operation',
            method: 'post',
            data: { userOpusIdList: [id], ...params },
          });
          await anchorDetailStore.getAnchorDetail();
          message.success('操作成功');
        },
      }),
    [anchorDetailStore, id],
  );

  return (
    <FlatAuth
      type={anchorDetailStore.type}
      flatAuthStore={flatAuthStore}
      vitaAuditReasonList={anchorDetailStore.vitaAuditReasonList}
    />
  );
});
