import { type BaseData, request, transformKeys } from '@/utils';
import { Button, Form, type FormInstance, TreeSelect } from 'antd';
import type { BaseOptionType } from 'antd/es/select';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

interface Options {
  callback?: (params, ...args: any[]) => Promise<any>;
  callbackClose?: () => void;
  title?: string;
  categoryType?: number;
  libId?: unknown[];
  liveTypeId?: number;
  liveTypeName?: string;
}

export class SliceStore {
  @observable public sliceData: [];
  public formRef = React.createRef<FormInstance>();
  @observable.ref options: Options = {};
  @observable public isSubmit = false;
  private getTagData = async () => {
    const info = await request<BaseData<any>>({
      url: '/pbb/platform/anon/system/liveType/list',
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    });

    this.sliceData = transformKeys(info.data, {
      name: 'label',
      id: 'value',
      childrenList: 'children',
    });
    this.sliceData.forEach((item: BaseOptionType) => {
      item.disabled = true;
    });
  };

  @action public handleOpen = (options: Options) => {
    this.options = options;
    this.getTagData();
  };

  @action public handleSubmit = async () => {
    const values = await this.formRef.current.validateFields();

    this.isSubmit = true;
    try {
      await this.options.callback(values);
    } finally {
      this.isSubmit = false;
      this.handleClose();
    }
  };
  @action public handleClose = () => {
    this.options.callbackClose();
  };
}

const SliceModal = ({
  store: { options, sliceData, isSubmit, formRef, handleSubmit, handleClose },
}: { store: SliceStore }) => {
  return (
    <>
      <Form
        ref={formRef}
        layout={'vertical'}
        autoComplete="off"
        initialValues={{
          liveTypeId:
            options?.liveTypeId && options?.liveTypeName ? [options?.liveTypeId, options?.liveTypeName] : null,
        }}
      >
        <Form.Item name="liveTypeId">
          <TreeSelect
            allowClear={true}
            treeCheckable={false}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            placeholder={'请选择切片类目'}
            treeNodeFilterProp="label"
            showSearch={true}
            treeData={sliceData}
            labelInValue={true}
            maxTagCount={10}
          />
        </Form.Item>
        <div className="flex justify-end">
          <Button
            type="primary"
            loading={isSubmit}
            className="ml-10"
            onClick={handleSubmit}
          >
            确定
          </Button>
          <Button
            className="ml-2"
            onClick={handleClose}
          >
            取消
          </Button>
        </div>
      </Form>
    </>
  );
};

export default observer(SliceModal);
